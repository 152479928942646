import { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import 'animate.css';
import './App.scss';
import logo from './assets/images/micdroptextfordarkbg.png';
import logo2 from './assets/images/micdroptext.png';
import broccoli from './assets/images/wbroccoli.png';
import bbroccoli from './assets/images/bbroccoli.png';
import mic from './assets/images/asset5.png';
import rapper from './assets/images/asset4.png';
import rapperwmic from './assets/images/asset6.png';
import IG from './assets/images/ig.svg';
import YT from './assets/images/yt.svg';
import SC from './assets/images/sc.svg';
import useAnim from './assets/hooks/useAnim';
import mddark from './assets/images/micdroptextfordarkbg.png';
import mdlight from './assets/images/micdroptext.png';

const artists = [
  {
    id: '0',
    name: 'DRGN',
    img: 'images/drgn1.png',
    link: 'https://www.instagram.com/drgn.ad/',
  },
  {
    id: '1',
    name: 'NTITLED',
    img: 'images/ntitled1.png',
    link: 'https://ampl.ink/q88ql',
  },
  {
    id: '3',
    name: 'DBL Z',
    img: 'images/dblz.png',
    link: 'https://dblz.broccolirecords.com/',
  },
  {
    id: '4',
    name: 'Sidoo Bee',
    img: 'images/sidoobee.png',
    link: 'https://www.instagram.com/sidoobee/',
  },
  {
    id: '5',
    name: 'RA3F',
    img: 'images/ra3f.png',
    link: 'https://www.instagram.com/rv.jz/',
  },
];
const labels = [
  {
    id: '0',
    name: 'Diggn Productions',
    img: 'images/diggn.png',
    link: 'https://linktr.ee/DiggN',
  },
  {
    id: '1',
    name: 'Broccoli Records',
    img: 'images/broccolirecords.png',
    link: 'https://www.broccolirecords.com/',
  },
];

function App() {
  const [status, setStatus] = useState('idle');
  const [theme, setTheme] = useState('dark');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('empty');
  const [linkStatus, setLinkStatus] = useState('idle');
  const [SCMessage, setSCMessage] = useState('');
  const [SCError, setSCError] = useState('empty');
  const [YTMessage, setYTMessage] = useState('');
  const [YTError, setYTError] = useState('empty');
  const form = useRef();
  const link = useRef();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidSC(url) {
    const regex = new RegExp('https://soundcloud.com/[a-zA-Z0-9]+');
    return regex.test(url);
  }
  function isValidYT(url) {
    const regex = new RegExp('https://www.youtube.com/[A-Za-z0-9]+');
    return regex.test(url);
  }

  const sendit = (e) => {
    e.preventDefault();
    setStatus('sending');
    if (error === null) {
      sendEmail(e);
    } else {
      alert(error);
      setStatus('idle');
    }
  };
  const sendyo = (e) => {
    e.preventDefault();
    setLinkStatus('sending');
    if (SCError === null) {
      sendLink(e);
    } else if (YTError === null) {
      sendLink(e);
    } else if (SCError !== null) {
      alert(SCError);
      setLinkStatus('idle');
    } else {
      alert(YTError);
      setLinkStatus('idle');
    }
  };

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('*wrong email');
    } else {
      setError(null);
    }

    setMessage(event.target.value);
  };

  const handleSC = (event) => {
    if (!isValidSC(event.target.value)) {
      setSCError('*what');
    } else {
      setSCError(null);
    }

    setSCMessage(event.target.value);
  };
  const handleYT = (event) => {
    if (!isValidYT(event.target.value)) {
      setYTError('*what');
    } else {
      setYTError(null);
    }

    setYTMessage(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2vzcg8p',
        'template_319u5ar',
        form.current,
        'baiYOuNQ2HuEal1Ra'
      )
      .then(
        (result) => {
          setStatus('sent');
        },
        (error) => {
          setStatus('idle');
        }
      );
  };
  const sendLink = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2vzcg8p',
        'template_osmelk6',
        link.current,
        'baiYOuNQ2HuEal1Ra'
      )
      .then(
        (result) => {
          setLinkStatus('sent');
        },
        (error) => {
          setLinkStatus('idle');
        }
      );
  };

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  useEffect(() => {
    document.querySelector('#form').className = status;
  }, [status]);
  useEffect(() => {
    document.querySelector('#linkform').className = linkStatus;
  }, [linkStatus]);

  useAnim('fade-in');
  useAnim('fade-in-delayed');
  useAnim('fade-in-late');

  return (
    <>
      <div className={`App ${theme}`}>
        <video className='videoTag' autoPlay playsInline loop muted>
          <source src='images/bgvid.mp4' type='video/mp4' />
        </video>
        <div className='App-header'>
          {theme === 'light' ? (
            <>
              <img
                className='rapper animate__animated animate__fadeIn'
                src={rapper}
                alt=''
              />
              <img
                className='rapper animate__animated animate__fadeOut'
                src={rapperwmic}
                alt=''
              />
              <img
                className='mic animate__animated animate__fadeInDown'
                src={mic}
                alt=''
              />
            </>
          ) : null}

          <img
            src={theme === 'light' ? bbroccoli : broccoli}
            className='broccoli fade-in-delayed'
            alt='logo'
            onClick={toggleTheme}
          />
          <img
            src={theme === 'light' ? logo2 : logo}
            className='App-logo fade-in'
            alt='logo'
          />
          <span className='code big fade-in-delayed'>TBA</span>
        </div>
        <div className='artists'>
          <h1 className='code fade-in'>expected appearances</h1>
          <div className='list'>
            {artists.map((artist) => (
              <div className='artist fade-in' key={artist.id}>
                <a
                  className={`artistlink fade-in-delayed ${theme}`}
                  href={artist.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <h1 className='pixel'>{artist.name}</h1>
                </a>
                <div className='artistsimg'>
                  <img src={artist.img} alt={artist.name} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='labels'>
          <h2 className='code fade-in'>and artisis from</h2>
          <div className='list'>
            {labels.map((label) => (
              <div className='label fade-in' key={label.id}>
                <a href={label.link}>
                  <h1 className='pixel fade-in-delayed'>{label.name}</h1>
                </a>
                <img srcSet={label.img} src={label.img} alt={label.name} />
              </div>
            ))}
          </div>
        </div>
        <div className='sendlink fade-in'>
          <video className='videoTag' autoPlay playsInline loop muted>
            <source src='images/bgvid2.mp4' type='video/mp4' />
          </video>
          <div className='msg'>
            <span className='m1 pixel big fade-in-delayed'>
              wanna join the line-up?
            </span>
            <span className='pixel join fade-in-late'>submit a link</span>
          </div>
          <div className='linkinp'>
            <form id='linkform' ref={link} onSubmit={sendyo}>
              <div className='inputs'>
                {linkStatus === 'idle' ? (
                  <>
                    <div className='outer'>
                      <div className='inner'>
                        <label className='plat code big'>Soundcloud</label>
                        <div className='icon'>
                          <img src={SC} alt='IG' />
                        </div>
                        <div className='inperr'>
                          <input
                            className={`pixel ${theme}`}
                            type='url'
                            name='linksc'
                            value={SCMessage}
                            onChange={handleSC}
                          />
                          {SCError === '*what' && (
                            <span className='code small'>{SCError}</span>
                          )}
                        </div>
                      </div>
                      <div className='inner'>
                        <label className='plat code big'>YouTube</label>
                        <div className='icon'>
                          <img src={YT} alt='IG' />
                        </div>

                        <div className='inperr'>
                          <input
                            className={`pixel ${theme}`}
                            type='url'
                            name='linkyt'
                            value={YTMessage}
                            onChange={handleYT}
                          />
                          {YTError === '*what' && (
                            <span className='code small'>{YTError}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    {(!SCError || !YTError) && (
                      <div className='button'>
                        <input
                          className={`code ${theme}`}
                          type='submit'
                          value='Send'
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {linkStatus === 'sending' ? (
                  <>
                    <div className='sending'>
                      <span className='code big'>sending...</span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {linkStatus === 'sent' ? (
                  <>
                    <div className='thanks'>
                      <span className='code big'>
                        thanks for the submission
                      </span>
                      <span className='pixel'>
                        We'll give it a listen and let you know
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={`fade-in form ${theme} ${status}`}>
          <div className='contact pixel big '>
            contact us
            <a
              href='https://www.instagram.com/themicdropsa/'
              target='_blank'
              rel='noreferrer'
            >
              <img src={IG} alt='IG' />
            </a>
          </div>
          <form id='form' ref={form} onSubmit={sendit}>
            {status === 'idle' ? (
              <>
                <div className='name'>
                  <label className='code'>Name</label>
                  <input className='pixel' type='text' name='user_name' />
                </div>
                <div className='email'>
                  <label className='code'>Email</label>
                  <input
                    className='pixel'
                    type='email'
                    name='user_email'
                    value={message}
                    onChange={handleChange}
                  />
                  {error === '*wrong email' && (
                    <span className='code small'>{error}</span>
                  )}
                </div>
                <div className='message'>
                  <label className='code'>Message</label>
                  <textarea className='pixel' name='message' />
                </div>
                {error === null && (
                  <div className='button'>
                    <input className='code' type='submit' value='Send' />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            {status === 'sending' ? (
              <>
                <div className='sending'>
                  <span className='code big'>sending...</span>
                </div>
              </>
            ) : (
              <></>
            )}
            {status === 'sent' ? (
              <>
                <div className='thanks'>
                  <span className='code big'>thanks for the message</span>
                  <span className='pixel'>
                    We'll get to you as soon as we get it
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
        <div className='btmlogocnt'>
          {theme === 'dark' ? (
            <>
              <img className='btmlogo' src={mddark} alt='' />
            </>
          ) : (
            <>
              <img className='btmlogo' src={mdlight} alt='' />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
